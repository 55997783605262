import {Box, Checkbox, FormControlLabel, FormGroup} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import React from "react";
import {Helmet} from "react-helmet";
import {ConfirmationDialog} from "../../../components/Dialogs";
import Wizard from "../../../components/forms/Wizard";
import {LegacyUILink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, usePostCurrentPage} from "../../../hooks/useSentinelAPI";
import CloneProjectFields from "./Pages/CloneProjectFields";
import DefaultsFields from "./Pages/DefaultsFields";
import GeneralInfoFields from "./Pages/GeneralInfoFields";
import InsuranceFields from "./Pages/InsuranceFields";
import MarkupsFields from "./Pages/MarkupsFields";
import TeamFields from "./Pages/TeamFields";

const CreateProjectWizard = (props) => {
  const {...rest} = props;
  const [showCloneConfirmation, setShowCloneConfirmation] = React.useState(false);
  const [cloneSubcontractors, setCloneSubcontractors] = React.useState(false);
  const [projectToClone, setProjectToClone] = React.useState(null);
  const [cloneProjectName, setCloneProjectName] = React.useState(null);
  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      initialFormData: {
        generalInfo: {},
        insurance: {},
        defaults: {},
        team: {},
        markups: [],
      },
      locationLookup: {},
    },
  });
  const pageData = pageDataQuery.data;
  const blockUI = useBlockUI();
  const postCurrentPage = usePostCurrentPage();

  return (
    <>
      <Helmet title={"Create Project Wizard"} />

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>Create Project Wizard</PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <Alert severity="info">All information entered into this wizard can be changed at a later date if needed.</Alert>
      <Box mb={2} />
      <PaperPanel>
        <PaperPanel.Header></PaperPanel.Header>
        <PaperPanel.Body>
          <Wizard
            onSubmit={(values) => {
              blockUI.blockUI("Saving...");
              postCurrentPage.mutateAsync(values).then((response) => {
                window.location.href = response.url;
              });
            }}
            initialValues={pageData.initialFormData}
            submitButtonLabel="Create Project"
          >
            <Wizard.Page
              title="Clone Project"
              onCompleted={(values, form) => {
                if (values?.cloneProject) {
                  setCloneProjectName(values["cloneProject"]?.newProjectName);
                  setProjectToClone(values["cloneProject"]?.projectToClone);
                  setShowCloneConfirmation(true);
                }
              }}
            >
              <CloneProjectFields namespace="cloneProject" />
            </Wizard.Page>
            <Wizard.Page title="General Info">
              <GeneralInfoFields namespace="generalInfo" locationLookup={pageData.locationLookup} />
            </Wizard.Page>
            <Wizard.Page title="Defaults">
              <DefaultsFields namespace="defaults" />
            </Wizard.Page>
            <Wizard.Page title="Team">
              <TeamFields namespace="team" />
            </Wizard.Page>
            <Wizard.Page title="Insurance">
              <InsuranceFields namespace="insurance" />
            </Wizard.Page>
            <Wizard.Page title="Markups">
              <MarkupsFields namespace="markups" />
            </Wizard.Page>
          </Wizard>
        </PaperPanel.Body>
      </PaperPanel>

      <LegacyUILink href={`/projects/create_project_wizard/`} mt={2} />

      <ConfirmationDialog
        isOpen={showCloneConfirmation}
        onDeny={() => setShowCloneConfirmation(false)}
        onApprove={() => {
          blockUI.blockUI("Cloning...");
          setShowCloneConfirmation(false);
          postCurrentPage
            .mutateAsync({
              newProjectName: cloneProjectName,
              projectToClone: projectToClone,
              cloneSubcontractors: cloneSubcontractors,
            })
            .then((response) => {
              window.location.href = response.url;
            });
        }}
        title="Clone This Project?"
      >
        <Box>
          You want to clone <strong>{projectToClone?.display}</strong> to a new project:{" "}
          <strong>{cloneProjectName}</strong>
        </Box>
        <Box>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={cloneSubcontractors}
                  onChange={(e) => setCloneSubcontractors(e.target.checked)}
                  name="cloneSubcontractors"
                  color="primary"
                />
              }
              label="Clone Subcontractors In Directory"
            />
          </FormGroup>
        </Box>
      </ConfirmationDialog>
      {/* <h2>PageData</h2>
      <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
    </>
  );
};

export default CreateProjectWizard;
